'use strict';

angular.module('body-layout', [
    'ui.router',
])
.provider('BodyLayout', function(){
    var __states = {};

    this.setLayout = function(layout, states){
        for(var index in states){
            __states[states[index]] = layout;
        }

        return this;
    };

    this.$get = ['$rootScope', function($rootScope){
        return {
            update: function(toState){
                var layout = null;

                var toStateParts = toState.name.split('.');

                if (!!__states[toState.name]) {
                    layout = __states[toState.name];
                } else if (toStateParts.length) {
                    while(!layout && toStateParts.length) {
                        toStateParts.pop();

                        var testStateName = toStateParts.join('.') + '.*';
                        if (!!__states[testStateName]) {
                            layout = __states[testStateName];
                        }
                    }
                }

                $rootScope.bodyLayout = layout;
            }
        }
    }];
})
.run(['$transitions', 'BodyLayout', function($transitions, BodyLayout){
    $transitions.onSuccess({}, function(transition){
        BodyLayout.update(transition.to());
    });
}]);
