angular.module('link-disabled', [])
    .directive('linkDisabled', function() {
        return {
            compile: function (tElement, tAttrs) {
                // Disable ngClick
                if (tAttrs.ngClick) {
                    tAttrs.ngClick = '!(' + tAttrs.linkDisabled + ') && (' + tAttrs.ngClick + ')';
                }

                return function(scope, iElement, iAttrs) {
                    // Toggle "disabled" class
                    scope.$watch(iAttrs.linkDisabled, function(newValue) {
                        if (newValue !== undefined) {
                            iElement.toggleClass('disabled', !!newValue);
                        }
                    });

                    // Disable href on click
                    iElement.on('click', function (event) {
                        if (scope.$eval(iAttrs.linkDisabled)) {
                            event.preventDefault();
                        }
                    });
                };
            },
        };
    });
